<template>
    <v-snackbar
		:timeout="timeout"
		:content-class="contentClass"
		:color="color"
		v-model="show"
		bottom
		app
		elevation="24"
		transition="fade-transition"
	>
		<v-icon small class="mr-2 d-inline" color="white">{{ icon }}</v-icon>
		<span class="body-2 white--text font-weight-medium">{{ text }}</span>
	</v-snackbar>
</template>

<script>

export default {
	name: "SnackBar",
	data: () => ({
		bottom: false,
		ready: false,
		show: false,
		icon: '',
		color: 'success',
		text: '',
		timeout: 6000,
    }),
    props: {
    },
	components: {

	},
	computed: {
		contentClass(){
			let className = 'app-snack';
			if(this.bottom){
				className += ' snack-bottom';
			}
			return className;
		}
	},
	methods: {
		initData(data){
			this.show = false;
			this.color = data.color || 'success';
			this.text = data.text || '';
			this.icon = this.getIcon();
			this.bottom = data.bottom || false;
			this.timeout = data.timeout || 2000;
			this.show = true;
			// console.log(data);
		},
		getIcon(){
			if(this.color == 'error'){
				return 'fa fa-times-circle';
			}
			if(this.color == 'info'){
				return 'fa fa-info-circle';
			}
			return 'fa fa-check-circle';
		}
	},
	mounted(){
	},
	created() {
		this.$eventHub.$on('snackBar', ($event) => { this.initData($event); });
	},
	watch: {
		
	}
}
</script>